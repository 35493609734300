import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [

      {
        path: '/dashboard',
        name: 'home',
        component: () => import('@/pages/a08-common/FrontPage'),
      },

      {
        path: '/workplace',
        name: 'Рабочие страницы',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        //redirect: '/workplace/anton1',
        children: [
          {
            path: '/workplace/anton1',
            name: 'Антон1',
            component: () => import('@/pages/workplace/Anton1'),
          },
          {
            path: '/workplace/anton2',
            name: 'Антон2',
            component: () => import('@/pages/workplace/Anton2'),
          },
            ],
          },

      {
        path: '/components',
        name: 'Кастомные компоненты',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        //redirect: '/workplace/anton1',
        children: [
          {
            path: '/components/file_upload',
            name: 'Загрузка файлов',
            component: () => import('@/pages/components/FileUploadPopupDemo'),
          },
          {
            path: '/components/phone-input',
            name: 'Поле ввода телефона',
            component: () => import('@/pages/components/PhoneInputDemo')
          },
          {
            path: '/components/autocomplete',
            name: 'Поле autocomplete',
            component: () => import('@/pages/components/AutocompleteDemo.vue'),
          },
          {
            path: '/components/dates-range',
            name: 'Элемент ввода диапазона дат',
            component: () => import('@/pages/components/DatesRangeDemo.vue'),
          },
          {
            path: '/components/server-errors',
            name: 'Серверные ошибки',
            component: () => import('@/pages/components/ServerErrors.vue'),
          },
          {
            path: '/components/cop-rub-convert',
            name: 'Конвертация рублей и копеек',
            component: () => import('@/pages/components/CopRubConvertDemo.vue'),
          },
          {
            path: '/components/contacts-edit',
            name: 'Редактирование контактов',
            component: () => import('@/pages/components/ContactsEditDemo'),
          },
          {
            path: '/components/comments-popup',
            name: 'Модал комментариев',
            component: () => import('@/pages/components/CommentsPopupDemo.vue'),
          },
          {
            path: '/components/phones-edit',
            name: 'Редактирование телефонов 22323',
            title: 'Редактирование телефонов',
            component: () => import('@/pages/components/PhonesEditDemo.vue')
          },
          {
            path: '/components/phones-list',
            name: 'Список телефонов объекта',
            title: 'Редактирование телефонов',
            component: () => import('@/pages/components/PhonesListDemo.vue')
          },
          {
            path: '/components/social-link',
            name: 'Ссылка на соц. сети и мессенджеры',
            component: () => import('@/pages/components/SocialLinkDemo.vue')
          },
        ],
      },

      {
        path: '/guides',
        name: 'Руководства',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        //redirect: '/workplace/anton1',
        children: [
          {
            path: '/guides/validation',
            name: 'Валидация',
            component: () => import('@/pages/guides/Validations'),
          },
          {
            path: '/guides/storage',
            name: 'Хранилище',
            component: () => import('@/pages/guides/StorageDemo'),
          },
          {
            path: '/guides/storage_table',
            name: 'Хранилище+для таблицы',
            component: () => import('@/pages/guides/StorageTable'),
          },
          {
            path: '/guides/common',
            name: 'Верстка',
            component: () => import('@/pages/guides/Common'),
          },
          {
            path: '/guides/form',
            name: 'Демо формы',
            component: () => import('@/pages/guides/FormDemo'),
          },
          {
            path: '/guides/page',
            name: 'Демо страницы',
            component: () => import('@/pages/guides/PageDemoLayout'),
          },
          {
            path: '/guides/multiform',
            name: 'Демо мульти-формы',
            component: () => import('@/pages/guides/Multiform'),
          },
          {
            path: '/guides/table',
            name: 'Демо таблицы',
            component: () => import('@/pages/guides/TableDemo'),
          },
          {
            path: '/guides/popup',
            name: 'Демо попап диалога',
            component: () => import('@/pages/guides/PopupDemo'),
          },
        ],
      },


      // новые добавлять сюда
			{
				path: '/organization/popups',
				name: 'Создание или редактирование организации',
				component: () => import('@/pages/a01-agent/OrganizationEditPopup')
			},
      {
        path: '/person/create',
        name: 'Создание персоны',
        component: () => import('@/pages/a01-agent/PersonEditPopup')
      },



      {
        path:'/webmaster/bookings_forms_edit',
        name:'Бронирование редактирование формы',
        component: () => import('@/pages/a03-webmaster/BookingFormsEdit')
      },
      {
        path:'/webmaster/bookings_forms_integration',
        name:'Интеграция с формами бронирования',
        component: () => import('@/pages/a03-webmaster/BookingFormsIntegration')
      },
      {
        path:'/webmaster/bookings_forms_list',
        name:'Список форм бронирования',
        component: () => import('@/pages/a03-webmaster/BookingFormsEdit')
      },
      {
        path: '/agent/agent_fee',
        name: 'агентское вознаграждение',
        component: () => import('@/pages/a01-agent/AgentFee')
      },
      {
        path: '/agent/agent_profile',
        name: 'Агент профиль',
        component: () => import('@/pages/a01-agent/AgentProfile')
      },
      {
        path: '/agent/agent_tours_list',
        name: ' Список тур агентов',
        component: () => import('@/pages/a01-agent/AgentToursList')
      },
      {
        path: '/agent/check_in_out',
        name: 'Список тур агентов',
        component: () => import('@/pages/a01-agent/CheckInOut')
      },
      {
        path: '/agent/hotel_find_helper',
        name: 'Найти помощника',
        component: () => import('@/pages/a01-agent/HotelFindHelper')
      },
      {
        path: '/agent/lead_edit/:id',
        name: 'Редактирование лида',
        props: true,
        component: () => import('@/pages/a01-agent/LeadEdit')
      },
      {
        path: '/agent/leads_list',
        name: {
          title: "Список лидов",
          name: "agent__leads_list"
        },
        component: () => import('@/pages/a01-agent/LeadsList')
      },
      {
        path: '/agent/leads_view/:id',
        name: 'Просмотр лида',
        props: true,
        component: () => import('@/pages/a01-agent/LeadView')
      },
      {
        path: '/agent/script_play',
        name: 'Запустить скрипт',
        component: () => import('@/pages/a01-agent/ScriptPlay')
      },
      {
        path: '/agent/tour_edit',
        name: 'Редактирование тура',
        component: () => import('@/pages/a01-agent/TourEdit')
      },
      {
        path: '/agent/tour_search_demo',
        name: 'Найти тур или заявку',
        component: () => import('@/pages/a01-agent/TourSearchDemo')
      },
      {
        path: '/agent/tour_search_results',
        name: 'Результаты поиска туров и заявок',
        component: () => import('@/pages/a01-agent/TourSearchResults')
      },
      {
        path:'/agency/agency_tour_operate',
        name: "агентство тур оператор",
        component: () => import('@/pages/a02-agency/AgencyTourOperate')
      },
      {
        path:'/agency/agency_tours_list',
        name:"список агентов тура",
        component: () => import('@/pages/a02-agency/CustomersList')
      },
      {
        path:'/agency/leads_list',
        name: {
          title: "Список лидов",
          name: "agency__leads_list"
        },
        component: () => import('@/pages/a02-agency/LeadsList')
      },
      {
        path:'/agency/operations_log',
        name: "операционные логи",
        component: () => import('@/pages/a02-agency/OperationsLog')
      },
      {
        path:'/agency/system_contracts',
        name: "Системные контракты",
        component: () => import('@/pages/a02-agency/SystemContracts')
      },
      {
        path:'/agency/tele_call_list',
        name: "список телезвонков",
        component: () => import('@/pages/a02-agency/TeleCallsList')
      },
      {
        path:'/agency_cc/agency_entries_list',
        name: "Агентство список записей",
        component: () => import('@/pages/a04-agency-acc/AgencyEntriesList')
      },
      {
        path:'/agency_cc/agency_entry_create',
        name: "Агентство запись создать",
        component: () => import('@/pages/a04-agency-acc/AgencyEntryCreate')
      },
      {
        path:'/agency_cc/export',
        name: "экспорт",
        component: () => import('@/pages/a04-agency-acc/AgencyExport')
      },
      {
        path:'/agency_cc/agency_payments_create',
        name: "создать платеж агентства",
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentCreate')
      },
      {
        path:'/agency_cc/agency_payments_import',
        name: "выгрузить платеж агентства",
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentsImport')
      },
      {
        path:'/agency_cc/agency_payments_list',
        name: "список платежей агентства",
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentsList')
      },
      {
        path:'/agency_cc/agency_payments_split',
        name: "разбить выплаты агентства",
        component: () => import('@/pages/a04-agency-acc/AgencyPaymentSplit')
      },
      {
        path:'/agency_cc/agency_reports',
        name: "отчеты агентства",
        component: () => import('@/pages/a04-agency-acc/AgencyReports')
      },
      {
        path:'/agency_cc/agency_system_documents',
        name: "агентство системные документы",
        component: () => import('@/pages/a04-agency-acc/AgencySystemDocuments')
      },
      {
        path:'/agency_cc/agency_system_settlements',
        name: "агентская система расчетов",
        component: () => import('@/pages/a04-agency-acc/AgencySystemSettlements')
      },
      {
        path:'/agency_cc/agent_fee_calculation_create',
        name: "создать расчет агентского вознаграждения",
        component: () => import('@/pages/a04-agency-acc/AgentFeeCalculationCreate')
      },
      {
        path:'/agency_cc/agent_fee_calculation_list',
        name: "список расчетов агентского вознаграждения",
        component: () => import('@/pages/a04-agency-acc/AgentFeeCalculationsList')
      },
      {
        path:'/agency_cc/agent_fee_list',
        name: "список  агентских вознаграждения",
        component: () => import('@/pages/a04-agency-acc/AgentFeeList')
      },
      {
        path:'/system/companies_list',
        name: "список компаний",
        component: () => import('@/pages/a05-system/CompaniesList')
      },
      {
        path:'/system/company_edit',
        name: "редактировать компанию",
        component: () => import('@/pages/a05-system/CompanyEdit')
      },
      {
        path:'/system/company_vue',
        name: "компания вью",
        component: () => import('@/pages/a05-system/CompanyView')
      },
      {
        path:'/system/user_list',
        name: "список пользователей",
        component: () => import('@/pages/a05-system/UsersList')
      },
      {
        path:'/accountant/agency_create_entry',
        name: "агентство создать юридическое лицо",
        component: () => import('@/pages/a06-accountant/AgencyCreateEntry')
      },
      {
        path:'/accountant/agency_entries_list',
        name: "список записей агентства",
        component: () => import('@/pages/a06-accountant/AgencyEntriesList')
      },
      {
        path:'/accountant/system_agency_contract_edit',
        name: "редактирование контракта с системным агентством",
        component: () => import('@/pages/a06-accountant/SystemAgencyContractEdit')
      },
      {
        path:'/accountant/system_agency_contract_list',
        name: "системные агентские контракты",
        component: () => import('@/pages/a06-accountant/SystemAgencyContractsList')
      },
      {
        path:'/accountant/system_agency_plans_edit',
        name: "системное агентство планы редактировать",
        component: () => import('@/pages/a06-accountant/SystemAgencyPlansEdit')
      },
      {
        path:'/accountant/system_agency_plans_list',
        name: "список планов системного агентства",
        component: () => import('@/pages/a06-accountant/SystemAgencyPlansList')
      },
      {
        path:'/accountant/system_documents',
        name: "системные документы",
        component: () => import('@/pages/a06-accountant/SystemDocuments')
      },
      {
        path:'/accountant/system_export',
        name: "системный экспорт",
        component: () => import('@/pages/a06-accountant/SystemExport')
      },
      {
        path:'/accountant/system_hotelier_contract_edit',
        name: "системный договор отельера редактировать",
        component: () => import('@/pages/a06-accountant/SystemHotelierContractEdit')
      },
      {
        path:'/accountant/system_hotelier_contract_list',
        name: "системный договор отельера список",
        component: () => import('@/pages/a06-accountant/SystemHotelierContractsList')
      },
      {
        path:'/accountant/system_payment_create',
        name: "система оплаты создать",
        component: () => import('@/pages/a06-accountant/SystemPaymentCreate')
      },
      {
        path:'/accountant/system_payments_import',
        name: "система оплаты импорт",
        component: () => import('@/pages/a06-accountant/SystemPaymentsImport')
      },
      {
        path:'/accountant/system_payments_list',
        name: "система оплаты список",
        component: () => import('@/pages/a06-accountant/SystemPaymentsList')
      },
      {
        path:'/accountant/system_payment_split',
        name: "система платежей разбить",
        component: () => import('@/pages/a06-accountant/SystemPaymentSplit')
      },
      {
        path:'/accountant/system_reports',
        name: "система отчетов",
        component: () => import('@/pages/a06-accountant/SystemReports')
      },



       {
        path:'/manager/bookings_list',
        name:'Cписок броней',
        component: () => import('@/pages/a07-manager/ManagerBookingsList')
      },

      {
        path:'/manager/booking_operate',
        name:'Операции с бронями',
        component: () => import('@/pages/a07-manager/ManagerBookingOperate')
      },

      {
        path:'/manager/tours_list',
        name:'Cписок туров',
        component: () => import('@/pages/a07-manager/ManagerToursList')
      },

      {
        path:'/manager/tour_operate',
        name:'Операции с турами',
        component: () => import('@/pages/a07-manager/ManagerTourOperate')
      },
      {
        path:'/manager/quotes_list',
        name:'Cписок квот',
        component: () => import('@/pages/a07-manager/QuotesList')
      },

      {
        path:'/common/profile_view_demo',
        name: "Просмотр профайла",
        component: () => import('@/pages/a08-common/Profile/ProfileViewDemo')
      },

      {
        path:'/common/profile_edit_demo',
        name: "Просмотр профайла",
        component: () => import('@/pages/a08-common/Profile/ProfileEditDemo')
      },

      {
        path: '/users',
        name: 'Пользователи',
        component: () => import('@/views/Users'),
      },
      {
        path: '/employees',
        name: 'Сотрудники',
        component: () => import('@/views/Employees'),
      },
      {
        path: '/users/:id',
        name: 'Пользователь',
        props: true,
        component: () => import('@/views/User'),
      },
      {
        path: '/employee/:id',
        name: 'Сотрудник',
        props: true,
        component: () => import('@/views/Employee'),
      },
      {
        path: '/users/edit/:id',
        name: 'Изменить пользователя',
        props: true,
        component: () => import('@/views/UserEdit'),
      },
      {
        path: '/employee/edit/:id',
        name: 'Изменить сотрудника',
        props: true,
        component: () => import('@/views/EmployeeEdit'),
      },
      {
        path: '/contracts/agency',
        name: 'Договоры с агентствами',
        component: () => import('@/views/Contracts/ContractsAgency'),
      },
      {
        path: '/contracts/hotel',
        name: 'Договоры',
        component: () => import('@/views/Contracts/ContractsHotel'),
      },
      {
        path: '/contracts/agency/create',
        name: 'Создать договор агентства',
        component: () =>
          import('@/views/Contracts/Editor/ContractsAgencyEditor'),
      },
      {
        path: '/contracts/agency/edit/:id',
        name: 'Изменить договор агентства',
        props: true,
        component: () =>
          import('@/views/Contracts/Editor/ContractsAgencyEditor'),
      },
      {
        path: '/contracts/hoteiler/edit/:id',
        name: 'Изменить договор отеля',
        props: true,
        component: () =>
          import('@/views/Contracts/Editor/ContractsHoteilerEditor'),
      },
      {
        path: '/contracts/hoteiler/create',
        name: 'Создать договор отеля',
        component: () =>
          import('@/views/Contracts/Editor/ContractsHoteilerEditor'),
      },
      {
        path: '/profile',
        name: 'Профиль',
        component: () => import('@/pages/a08-common/Profile'),
      },
      {
        path: '/company',
        name: 'Компании',
        component: () => import('@/views/Company'),
      },
      {
        path: '/entitys',
        name: 'Юр. лица',
        component: () => import('@/views/Entitys'),
      },
      {
        path: '/entitys/create',
        name: 'Юр. лицо',
        props: true,
        component: () => import('@/views/EditEntity'),
      },
      {
        path: '/chat',
        name: 'chat',
        component: () => import('@/views/ChatPage'),
      },
      {
        path: '/applications/create',
        name: 'Логика продаж',
        component: () => import('@/views/CreateAplication'),
      },
      {
        path: '/company/create',
        name: 'Создать компанию',
        props: true,
        component: () => import('@/views/CreateCompany'),
      },
      {
        path: '/applications/default/create',
        name: 'Создать заявку',
        props: true,
        component: () => import('@/views/DefaultCreateAplication'),
      },
      {
        path: '/info',
        name: 'Справка',
        component: () => import('@/views/Info'),
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography'),
      },
      {
        path: '/tours',
        name: 'Tours',
        component: () => import('@/views/Tours'),
      },
      {
        path: '/hotel',
        name: 'Hotel',
        component: () => import('@/views/Hotel'),
      },
      {
        path: '/arrivals',
        name: 'Arrivals',
        component: () => import('@/views/Arrivals'),
      },
      {
        path: '/customer',
        name: 'Customer',
        component: () => import('@/views/Customer'),
      },
      {
        path: '/contracts',
        name: 'Contracts',
        component: () => import('@/views/Contracts'),
      },
      {
        path: '/calls',
        name: 'Calls',
        component: () => import('@/views/Calls'),
      },
      {
        path: '/payments',
        name: 'Payments',
        component: () => import('@/views/Payments'),
      },
      {
        path: '/entries',
        name: 'Entries',
        component: () => import('@/views/Entries'),
      },
      {
        path: '/docs',
        name: 'Docs',
        component: () => import('@/views/Docs'),
      },
      {
        path: '/reports',
        name: 'Reports',
        component: () => import('@/views/Reports'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select'),
          },
          {
            path: '/forms/multi-select',
            name: 'Multi Select',
            component: () => import('@/views/forms/MultiSelect'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets'),
      },
      {
        path: '/smart-table',
        name: 'Smart Table',
        component: () => import('@/views/smart-table/SmartTable'),
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/views/plugins/Calendar'),
      },
      {
        path: 'apps',
        name: 'Apps',
        redirect: '/apps/invoicing/invoice',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'invoicing',
            redirect: '/apps/invoicing/invoice',
            name: 'Invoicing',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [
              {
                path: 'invoice',
                name: 'Invoice',
                component: () => import('@/views/apps/invoicing/Invoice'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/hms',
    name: 'HMS',
    component: DefaultLayout,
    redirect: '/hotels',
    children: [
      {
        path: '/hotels',
        name: 'Список отелей',
        component: () => import('@/pages/hms/Hotels'),
      },
      {
        path: '/chessIndex',
        name: 'Шахматка',
        component: () => import('@/pages/hms/BookingPage'),
      },
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        path: 'restore',
        name: 'RestorePassword',
        component: () => import('@/views/pages/RestorePassword'),
      },
      {
        path: 'server',
        name: 'server',
        component: () => import('@/views/pages/SelectServer'),
      },
    ],
  },
  {
    path: '/apps/email',
    redirect: '/apps/email/inbox',
    name: 'Email',
    component: () => import('@/views/apps/email/EmailApp'),
    children: [
      {
        path: 'compose',
        name: 'Compose',
        component: () => import('@/views/apps/email/Compose'),
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: () => import('@/views/apps/email/Inbox'),
      },
      {
        path: 'message',
        name: 'Message',
        component: () => import('@/views/apps/email/Message'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
